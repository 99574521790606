.App {
    text-align: center;
  }
  
  .App-logo {
    padding: 20px;
    pointer-events: none;
    font-size: 50px;
    height: auto;
    margin: 40px
  }

  .App-logo a {
    text-align: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: var(--heading-1);
    font-weight: bold;
    text-decoration: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-flip 3s infinite;
              animation: App-logo-flip 3s infinite;
    }
  }
  
  @-webkit-keyframes App-logo-flip {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(180deg);
    }
  }
  
  @keyframes App-logo-flip {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(180deg);
    }
  }
  
  .App-header {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: var(--heading-1);
  }

  .Header-gradiant {
    background-image: url("./assets/banner.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }

  .App-footer {
    text-align: center;
    color: var(--primary-color);
    font-size: var(--font-size-large);
    padding: 10px;
    background-image: url("./assets/banner.png"); 
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;   
    margin: 80px 0 0 0;
  }
  
  .Gradient-line {
    height: 3px; /* Thickness of the line */
    width: 100%; /* Full width of the container */
    background-image: linear-gradient(.25turn, teal, lightblue); /* Gradient from teal to green */
    margin: 20px 0 40px 0; /* Some margin for visibility */
  }