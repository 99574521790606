:root {
    --primary-color: #504f4f;
    --secondary-color: #61e2af;
    --tertiary-color: #eeeeee;
    --color-teal: #135e4f;
    --color-black: #000000;
    --color-white: #ffffff;
}

/* Small devices (phones, 600px and below) */
@media only screen and (max-width: 600px) {
    :root {
        --heading-1: 30px;
        --heading-2: 22px;
        --heading-3: 18px;
        --font-size-large: 20px;
        --font-size-medium: 1.5rem;
        --font-size-small: 18px;
    }
}

/* Medium devices (tablets, 600px to 900px) */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    :root {
        --heading-1: 40px;
        --heading-2: 25px;
        --heading-3: 20px;
        --font-size-large: 20px;
        --font-size-medium: 1.5rem;
        --font-size-small: 20px;
    }
}

/* Large devices (desktops, 900px and above) */
@media only screen and (min-width: 901px) {
    :root {
        --heading-1: 45px;
        --heading-2: 25px;
        --heading-3: 20px;
        --font-size-large: 20px;
        --font-size-medium: 1.5rem;
        --font-size-small: 25px;
    }
}
