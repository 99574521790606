.high-value {
    color: var(--color-teal);
    font-size: var(--heading-2);
    font-weight: bold;
    margin-bottom: 40px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    text-align: center;
}
