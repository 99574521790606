.privacy {
    margin: 0 40px 40px 40px;
    display: -webkit-flex; /* Place the prefixed version first */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.privacy h1 {
    color: var(--color-teal);
    font-size: var(--heading-1);
    font-weight: bold;
}

p {
    font-size: var(--font-size-large);
}
