h4 {
  color: var(--primary-color);
  font-size: var(--heading-3);
  font-weight: bold;
  margin-bottom: 0px;
}

.page-component {
  text-align: center;
}

.page-component h1 {
  color: var(--color-teal);
  font-size: var(--heading-1);
  font-weight: bold;
  margin-bottom: 20px;
}

.page-component h2 {
  color: var(--primary-color);
  font-size: var(--heading-2);
  font-weight: bold;
  margin-bottom: 0;
}

.margin {
  margin: 40px 0;
}