.lease {
    text-align: center;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
}

.lease h1 {
  color: var(--color-teal);
  font-size: var(--heading-1);
  font-weight: bold;
  margin-bottom: 20px;
}