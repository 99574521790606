.make-offer {
    color: var(--primary-color);
    font-size: var(--heading-2);
    font-weight: bold;
    margin-bottom: 40px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.make-offer h1 {
    color: #135e4f;
    font-size: var(--heading-1);
    font-weight: bold;
    margin-bottom: 20px;
}