.home h1 {
  color: var(--color-teal);
  font-size: var(--heading-1);
  font-weight: bold;
  margin-bottom: 40px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  text-align: center;
}

.home h2 {
  color: var(--primary-color);
  font-size: var(--font-size-large);
  font-weight: bold;
  margin-bottom: 40px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  text-align: center;
}
