table {
border-collapse: separate;
text-indent: initial;
border-spacing: 2px;
width: 90%;
margin-left: auto;
margin-right: auto;
}

tr {
display: table-row;
height: 30px;
text-align: center;
vertical-align: inherit;
unicode-bidi: isolate; /* Using the standard property */
border-color: inherit;
}

td {
padding: 20px;
}

tr .background {
background-color: var(--tertiary-color);
}

thead tr {
background-color: var(--secondary-color);
height: 40px;
}
