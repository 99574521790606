#nav {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
  }
  
  #nav li a {
    padding: 8px 15px;
    font-weight: bold;
    color: var(--primary-color);
    font-size: var(--font-size-small);
  }
  
  @media only screen and (min-width: 901px) {
    #nav {
      display: -webkit-flex; /* Place the prefixed version first */
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  
    #nav li a {
      border-right: 1px solid #ccc;
      text-decoration: none;
    }
  }
  
  @media only screen and (min-width: 600px) {
    #nav li a {
      text-decoration: underline;
    }
  }